







































































































































































































































import Vue from "vue";
import {
  fieldAdd,
  fieldUpdate,
  fieldDetails,
  fieldDatasource,
  fieldValidate,
} from "@/api/salary/field";
import { groupList } from "@/api/user/group";
import { fieldTypeList, fieldGroupList } from "@/api/system/dict";
export default Vue.extend({
  props: ["isEditFlag", "baseEditInfo"],
  data() {
    return {
      editFormInfo: {
        name: null, //字段名称
        groupId: null, //适用分组
        defaultValue: null, //默认值
        enableByDefault: true, //是否启算薪默认勾选用
        fieldType: null, //字段类型
        groupLocation: [], //字段分组
        showInFinal: true, //是否在工资条展示
        dependencyFields: [], //依赖字段
        sortNum: 0, //排序值
        expression: null, //公式
        checkSession: null, //验证字段后获取
        displayWidth: 120, //显示宽度
      },
      editFormInfoRules: {
        name: [{ required: true, message: "请输入字段名称" }],
        defaultValue: [{ required: true, message: "请输入默认值" }],
        groupId: [{ required: true, message: "请选择适用分组" }],
        fieldType: [{ required: true, message: "请选择字段类型" }],
        groupLocation: [{ required: false, message: "请选择字段分组" }],
        expression: [{ required: true, message: "请输入计算公式" }],
        dataSource: [{ required: true, message: "请选择数据来源" }],
        field: [{ required: true, message: "请输入字段" }],
      },

      //字典
      groupTypeList: [],
      fieldTypeList: [],
      fieldGroupList: [],
      fieldDatasourceList: [],
    };
  },
  mounted() {
    this.fieldTypeList = fieldTypeList;
    this.fieldGroupList = fieldGroupList;
    groupList({}).then((res: any) => {
      this.groupTypeList = res.content.content.records || [];
    });
    fieldDatasource({}).then((res: any) => {
      for (let name in res.content) {
        this.fieldDatasourceList.push({
          name: name,
          list: res.content[name],
        });
      }
    });
    if (this.baseEditInfo.id) {
      fieldDetails(this.baseEditInfo.id).then((res) => {
        this.editFormInfo = this.$deepCopy(res.content);
        this.editFormInfo.dependencyFields = JSON.parse(
          this.editFormInfo.dependencyFields
        );
        if (this.editFormInfo.groupLocation) {
          this.editFormInfo.groupLocation = [this.editFormInfo.groupLocation];
        }
      });
    }
  },
  methods: {
    fieldTypeChange(val) {
      if (val == "仅参考字段") {
        this.editFormInfo.groupLocation = [];
        this.editFormInfoRules.groupLocation[0].required = true;
      } else {
        this.editFormInfo.groupLocation = null;
        this.editFormInfoRules.groupLocation[0].required = false;
      }
    },
    addFields() {
      this.editFormInfo.dependencyFields.push({
        dataSource: null,
        field: null,
      });
    },
    deleteFields(idx) {
      this.editFormInfo.dependencyFields.splice(idx, 1);
    },
    getChildField(val) {
      if (val) {
        let fieldList_ = this.fieldDatasourceList.find((c) => c.name == val);
        return fieldList_ ? fieldList_.list : [];
      } else {
        return [];
      }
    },
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }

      let obj = this.$deepCopy(this.editFormInfo);
      if (obj.groupLocation instanceof Array && obj.groupLocation.length > 0) {
        obj.groupLocation = obj.groupLocation[0];
      } else {
        obj.groupLocation = null;
      }
      obj.defaultValue = Number(obj.defaultValue);
      obj.dependencyFields = JSON.stringify(obj.dependencyFields);

      let validateObj = this.$deepCopy(obj);
      delete validateObj.checkSession;
      fieldValidate(validateObj).then((res) => {
        if (res.status == "SUCCESS") {
          obj.checkSession = res.content;
          if (obj.id) {
            fieldUpdate(obj.id, obj).then(() => {
              this.$message.success("保存成功");
              this.closeFn(true);
            });
          } else {
            fieldAdd(obj).then(() => {
              this.$message.success("保存成功");
              this.closeFn(true);
            });
          }
        } else {
          this.$message.warning("验证失败");
        }
      });
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
  },
});
