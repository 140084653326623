











































































































import Vue from "vue";
import { fieldList, fieldDelete, fieldQuery } from "@/api/salary/field";
import editForm from "./editForm.vue";
import sortForm from "./sortForm.vue";
export default Vue.extend({
  components: { editForm, sortForm },
  props: ["searchObj"],
  data() {
    return {
      tableData: [],
      editFormVisible: false,
      isEditFlag: false,
      baseEditInfo: {},
      sortFormVisible: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    //common
    getList() {
      this.getListData();
    },
    listEditFn(row, type) {
      this.editFormVisible = true;
      this.isEditFlag = type == "see" ? false : true;
      this.baseEditInfo = row;
    },
    listDeleteFn(row: any) {
      this.$confirm("是否删除本条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        fieldDelete(row.id).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getList();
        });
      });
    },
    addFn() {
      this.editFormVisible = true;
      this.baseEditInfo = this.$options.data().baseEditInfo;
      this.isEditFlag = true;
    },
    deleteFn() {
      console.log("删除");
    },
    closeFn(flag = false) {
      this.editFormVisible = false;
      if (flag) {
        this.getList();
      }
    },
    sortFn() {
      this.sortFormVisible = true;
    },
    sortCloseFn() {
      this.sortFormVisible = false;
    },
    //业务
    getListData() {
      const obj = Object.assign({}, this.searchObj);
      fieldQuery(obj).then((res: any) => {
        this.tableData = res.content.records || [];
      });
    },
  },
});
