











































































import Vue from "vue";
import { fieldSortSearch, fieldSortUpdate } from "@/api/salary/field";
import { groupList } from "@/api/user/group";
import authModule from "@/store/modules/auth";
import userModule from "@/store/modules/user";
export default Vue.extend({
  props: [],
  data() {
    return {
      editFormInfo: {
        id: null,
        additionSortNum: null, //增项
        groupId: null, //适用分组
        subtractionSortNum: null, //减项
        settingKey: "AS_SORT_SETTING",
      },
      editFormInfoRules: {
        additionSortNum: [{ required: true, message: "请输入字段名称" }],
        subtractionSortNum: [{ required: true, message: "请输入默认值" }],
        groupId: [{ required: true, message: "请选择适用分组" }],
      },
      isEditFlag: true,
      //字典
      groupTypeList: [],
    };
  },
  mounted() {
    this.setGroupId();
    groupList({}).then((res: any) => {
      this.groupTypeList = res.content.content.records || [];
    });
  },
  methods: {
    setGroupId() {
      const auths = authModule.auths;
      const auths_ = "ROLE::ADMIN,ROLE::PAYROLL_ADMIN";
      if (auths.filter((c) => auths_.indexOf(c) != -1).length == 0) {
        let userInfo = userModule.userInfo;
        this.editFormInfo.groupId = userInfo.groupId || "";
        this.isEditFlag = false;
        this.groupIdChange(this.editFormInfo.groupId);
      }
    },
    groupIdChange(val: any) {
      if (val) {
        fieldSortSearch(val).then((res) => {
          this.editFormInfo.id = res.content.id;
          this.editFormInfo.additionSortNum = res.content.additionSortNum;
          this.editFormInfo.subtractionSortNum = res.content.subtractionSortNum;
        });
      } else {
        this.editFormInfo.id = null;
        this.editFormInfo.groupId = null;
        this.editFormInfo.additionSortNum = null;
        this.editFormInfo.subtractionSortNum = null;
      }
    },
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }

      let obj = this.$deepCopy(this.editFormInfo);
      delete obj.groupId;
      fieldSortUpdate(this.editFormInfo.groupId, obj).then((res) => {
        if (res.status == "SUCCESS") {
          this.$message.success("保存成功");
          this.closeFn(true);
        } else {
          this.$message.warning("保存失败");
        }
      });
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
  },
});
