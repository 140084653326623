
































































import Vue from "vue";
import sundryCom from "./sundry";
import structureCom from "./structure";
import employeeCom from "./employee";
import { groupList } from "@/api/user/group";
export default Vue.extend({
  components: { sundryCom, structureCom, employeeCom },
  data() {
    return {
      tabsList: [
        { name: "薪资核算配置", value: "sundry" },
        { name: "薪资结构配置", value: "structure" },
        { name: "员工信息配置", value: "employee" },
      ],
      tabActive: "",
      //字典
      groupTypeList: [],

      searchObj: {
        group_id: "",
      },
    };
  },
  mounted() {
    groupList({}).then((res: any) => {
      this.groupTypeList = res.content.content.records || [];
    });
    this.tabClick(this.tabsList[0]);
  },
  methods: {
    tabClick(row) {
      if (this.tabActive != row.value) {
        this.tabActive = row.value;
      }
    },
    resetFn() {
      this.searchObj = this.$options.data().searchObj;
      this.searchFn();
    },
    searchFn() {
      if (this.tabActive == "sundry") {
        if (this.$refs.sundryCom) {
          this.$refs.sundryCom.getList();
        }
      } else if (this.tabActive == "structure") {
        if (this.$refs.structureCom) {
          this.$refs.structureCom.getList();
        }
      } else {
        if (this.$refs.employeeCom) {
          this.$refs.employeeCom.getList();
        }
      }
    },
  },
});
