



























































































import Vue from "vue";
import { memberExtraList, memberExtraDelete,memberExtraQuery } from "@/api/salary/employee";
import editForm from "./editForm.vue";
import { groupList } from "@/api/user/group";
export default Vue.extend({
  components: { editForm },
  props: ["searchObj"],
  data() {
    return {
      tableData: [],
      editFormVisible: false,
      isEditFlag: false,
      baseEditInfo: {},
      //字典
      groupTypeList: [],
    };
  },
  mounted() {
    groupList({}).then((res: any) => {
      this.groupTypeList = res.content.content.records || [];
    });
    this.getList();
  },
  methods: {
    //common
    getList() {
      this.getListData();
    },
    listEditFn(row, type) {
      this.editFormVisible = true;
      this.isEditFlag = type == "see" ? false : true;
      this.baseEditInfo = row;
    },
    listDeleteFn(row: any) {
      this.$confirm("是否删除本条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        memberExtraDelete(row.id).then((res) => {
          this.$message({
            type: "success",
            message: "删除成功",
          });
          this.getList();
        });
      });
    },
    addFn() {
      this.editFormVisible = true;
      this.baseEditInfo = this.$options.data().baseEditInfo;
      this.isEditFlag = true;
    },
    deleteFn() {
      console.log("删除");
    },
    closeFn(flag = false) {
      this.editFormVisible = false;
      if (flag) {
        this.getList();
      }
    },
    //业务
    getListData() {
      const obj = Object.assign({}, this.searchObj);
      memberExtraQuery(obj).then((res) => {
        this.tableData = res.content.records || [];
      });
    },
  },
});
