import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: (url as any)[vueAppEnv as any].home,
});

//员工信息额外字段
//新增字段
export const memberExtraAdd = (data: any): Promise<any> =>
  request.post("/api/member_extra", data);

//修改字段
export const memberExtraUpdate = (id: string, data: any): Promise<any> =>
  request.put("/api/member_extra/" + id, data);

//字段列表
export const memberExtraList = (params: any): Promise<any> =>
  request.get("/api/member_extra", { params });

//获取所有字段列表
export const memberExtraQuery = (params: any): Promise<any> => {
  const params_ = Object.assign({}, params, { select: "*" });
  // eslint-disable-next-line no-self-assign
  params_.group_id = "eq." + params_.group_id; //eq等于，like模糊
  return request.get("/api/member_extra/query", { params: params_ });
};

//字段删除
export const memberExtraDelete = (id: any): Promise<any> =>
  request.delete("/api/member_extra/" + id);

//获取字段信息
export const memberExtraDetails = (id: any): Promise<any> =>
  request.get("/api/member_extra/" + id);

//获取员工额外字段
export const memberExtra = (params: any): Promise<any> =>
  request.get("/api/member_extra", { params });
