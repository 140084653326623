











































































import Vue from "vue";
import {
  structureAdd,
  structureUpdate,
  structureDetails,
} from "@/api/salary/structure";
import { groupList } from "@/api/user/group";
export default Vue.extend({
  props: ["isEditFlag", "baseEditInfo"],
  data() {
    return {
      editFormInfo: {
        name: null, //名称
        groupId: null, //适用分组
        defaultValue: null, //默认值
      },
      editFormInfoRules: {
        name: [{ required: true, message: "请输入名称" }],
        defaultValue: [{ required: true, message: "请输入默认值" }],
        groupId: [{ required: true, message: "请选择适用分组" }],
      },

      //字典
      groupTypeList: [],
    };
  },
  mounted() {
    groupList({}).then((res: any) => {
      this.groupTypeList = res.content.content.records || [];
    });

    if (this.baseEditInfo.id) {
      structureDetails(this.baseEditInfo.id).then((res) => {
        this.editFormInfo = this.$deepCopy(res.content);
      });
    }
  },
  methods: {
    saveFn: async function () {
      let flag = true;
      (this.$refs.editFormInfoForm as any).validate((valid: boolean) => {
        if (!valid) {
          flag = false;
        }
      });
      if (!flag) {
        return;
      }

      let obj = this.$deepCopy(this.editFormInfo);
      obj.defaultValue = Number(obj.defaultValue);
      if (obj.id) {
        structureUpdate(obj.id, obj).then(() => {
          this.$message.success("保存成功");
          this.closeFn(true);
        });
      } else {
        structureAdd(obj).then(() => {
          this.$message.success("保存成功");
          this.closeFn(true);
        });
      }
    },
    closeFn(flag = false) {
      this.$emit("closeFn", flag);
    },
  },
});
